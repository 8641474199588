import { ReactNode } from 'react'
import Box from './Box'

export const Tab = <T extends unknown>({
  children,
  title,
}: {
  children: ReactNode
  title: T
}) => {
  return <>{children}</>
}

export const Tabs = <T extends unknown>({
  activeTitle,
  children,
  stickyHeader = false,
  className,
}: {
  activeTitle: T
  children: (React.ReactElement | false)[]
  stickyHeader?: boolean
  className?: string
}) => {
  const activeTab: ReactNode | null = children
    .filter((c) => c !== false)
    .find((c) => (c as React.ReactElement).props.title === activeTitle)
  if (className) return <Box className={className}>{activeTab}</Box>
  return <>{activeTab}</>
}
